import {AuthModel} from "../model/AuthModel";
import Config from "../utils/Config";

import {StorageConst} from "../utils/StorageConst";
import {SortDirection} from "../model/SortDirection";
import {ReportsOrderSortField} from "../model/ReportsOrderSortField";
import {ReportsItemSortField} from "../model/ReportsItemSortField";

export function auth(authModel: AuthModel): Promise<Response> {
    const url = `${Config.BASE_URL}/auth/web`;
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(authModel)
    })
}

function getApiSortDirection(sortDirection: SortDirection): string {
    switch (sortDirection) {
        case "asc":
            return "ASK";
        case "desc":
            return "DESC";
    }
}

export function getOrderReports(
    offset: number, size: number,
    fromTs: number, toTs: number,
    sortDirection: SortDirection, sortBy: ReportsOrderSortField
): Promise<Response> {
    const queryParams = new URLSearchParams({
        offset: offset.toString(),
        size: size.toString(),
        fromTs: fromTs.toString(),
        toTs: toTs.toString(),
        sortDirection: getApiSortDirection(sortDirection),
        sortBy: sortBy
    });
    const host = Config.BASE_URL!!.toString()
    const url = `${host}/api/reports/orders?${queryParams.toString()}`;
    return fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Merchant-ID': sessionStorage.getItem(StorageConst.MERCHANT_ID)!!,
            'Merchant-Auth-Token': sessionStorage.getItem(StorageConst.ACCESS_TOKEN)!!,
        },
    })
}

export function getItemsReports(
    offset: number, size: number,
    sortDirection: SortDirection, sortBy: ReportsItemSortField
): Promise<Response> {
    const queryParams = new URLSearchParams({
        offset: offset.toString(),
        size: size.toString(),
        sortDirection: getApiSortDirection(sortDirection),
        sortBy: sortBy
    });
    const url = `${Config.BASE_URL}/api/reports/items?${queryParams.toString()}`;
    return fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Merchant-ID': sessionStorage.getItem(StorageConst.MERCHANT_ID)!!,
            'Merchant-Auth-Token': sessionStorage.getItem(StorageConst.ACCESS_TOKEN)!!,
        }
    })
}