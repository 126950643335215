import * as React from 'react';
import Box from '@mui/material/Box';
import {List, ListItem} from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import './order.css';
import {DoublePricingDiscount} from "../../model/DoublePricingOrder";
const OrderTotal: React.FC<{ orderTotal: any }> = (props) => {
const {orderTotal} = props;
    console.log('order', orderTotal)
    return (
        <Box flexGrow={0} flexBasis={'content'} width={'100%'} boxShadow={'0px -4px 6px -7px #00000061'} paddingTop={'1vh'}>
            <List
                component="ul"
                sx={{
                    py: 0,
                    width: '100%'
                }}
            >
                {orderTotal && orderTotal.subtotalAmount != undefined ?
                    <ListItem className={'order-list-item'}>
                        <Typography className={'order-title-small order-bold-text-subtotal'} variant="subtitle1">Subtotal </Typography>
                        <Typography className={'wrap-amount'}>
                            <Typography className={'order-title-small order-bold-text-subtotal'} variant="subtitle1">{orderTotal.subtotalAmount.amount1} </Typography>
                            <Typography className={'order-title-small order-bold-text-subtotal'} variant="subtitle1">{orderTotal.subtotalAmount.amount2} </Typography>
                        </Typography>
                    </ListItem> : undefined}
                {orderTotal.discounts !=undefined && orderTotal.discounts.length > 0 ? orderTotal.discounts.map((i:DoublePricingDiscount) => (<ListItem sx={{
                        p: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: 1.43,
                        letterSpacing: '0.01071em',
                        color: 'rgba(232,55,55,0.6)',
                    }}>
                        <Typography className={'order-title-small'} variant="subtitle1">{i.name} </Typography>
                        <Typography className={'wrap-amount'}>
                            <Typography className={'order-title-small'} variant="subtitle1">{i.amount.a1 === 0 ? '' : `-${i.amount.amount1}`} </Typography>
                            <Typography className={'order-title-small'} variant="subtitle1">{i.amount.a2 === 0 ? '' : `-${i.amount.amount2}`} </Typography>
                        </Typography>
                    </ListItem>))
                    : undefined}
                {orderTotal && orderTotal.taxAmount != undefined ? <ListItem className={'order-list-item'} sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                }}>
                    <Typography className={'order-title-small'} variant="subtitle1">Tax Amount </Typography>
                    <Typography className={'wrap-amount'}>
                        <Typography className={'order-title-small'} variant="subtitle1">{orderTotal.taxAmount.amount1} </Typography>
                        <Typography className={'order-title-small'} variant="subtitle1">{orderTotal.taxAmount.amount2} </Typography>
                    </Typography>
                </ListItem> : undefined}
                {orderTotal && orderTotal.serviceChargeAmount != undefined ? <ListItem className={'order-list-item'} sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                }}>
                    <Typography className={'order-title-small'} variant="subtitle1">Service Charge </Typography>
                    <Typography className={'wrap-amount'}>
                        <Typography className={'order-title-small'} variant="subtitle1">{orderTotal.serviceChargeAmount.amount1} </Typography>
                        <Typography className={'order-title-small'} variant="subtitle1">{orderTotal.serviceChargeAmount.amount2} </Typography>
                    </Typography>
                </ListItem> : undefined}

                {orderTotal && orderTotal.totalAmount != undefined ?
                    <ListItem className={'order-list-item'}>
                    <Typography className={'order-title-big order-bold-text-total'} variant="subtitle1">Total </Typography>
                    <Typography className={'wrap-amount'}>
                        <Typography className={'order-title-big order-bold-text-total'} variant="subtitle1">{orderTotal.totalAmount.amount1} </Typography>
                        <Typography className={'order-title-big order-bold-text-total'} variant="subtitle1">{orderTotal.totalAmount.amount2} </Typography>
                    </Typography>
                </ListItem> : undefined}
            </List>

        </Box>
    );
}
export default OrderTotal;
