import React from 'react';

const AppUpdate = () => {
    return (
        <>
            <div className='failure' style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <h3>
                    Something went wrong <br/>
                    Please, make sure that you're using the latest Android app <br/>
                    And open the page again.
                </h3>
            </div>
        </>
    );
}

export default AppUpdate;
