import Dropdown, {Option} from "react-dropdown";
import React, {useEffect, useState} from "react";
import TimePicker from "../TimePicker";
import {getTsRange30Days, getTsRange7Days, getTsRangeToday, getTsRangeYesterday} from "../../utils/DateUtils";
import {Grid} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


interface DateFilterParams {
    onDateRangeChanged: any;
}

const DateFilter = ({onDateRangeChanged}: DateFilterParams) => {
    const [range, setRange] = useState(getTsRangeToday())
    const [onSelectValue, setOnSelectValue] = useState(false);
    const options = ['Today', 'Yesterday', '7 days', '30 days', 'Custom range']

    useEffect(() => {
        onDateRangeChanged(range);
    }, [range])
    const onSelect = (option: Option) => {
        const value = option.value;
        setOnSelectValue(value === options[4]);
        switch (value) {
            case options[0]:
                setRange(getTsRangeToday());
                break;
            case options[1]:
                setRange(getTsRangeYesterday());
                break;
            case options[2]:
                setRange(getTsRange7Days());
                break;
            case options[3]:
                setRange(getTsRange30Days());
                break;
        }
    }


    return (
        <>
            <Grid item><Dropdown arrowOpen={<ArrowDropDownIcon/>} arrowClosed={<ArrowRightIcon/>}
                                 className='wrap-drop-down' options={options} onChange={onSelect}
                                 value={options[0]}/></Grid>
            <Grid item>{onSelectValue && <TimePicker range={range} onDateRangeChanged={setRange}/>}</Grid>
        </>
    );
}

export default DateFilter;
