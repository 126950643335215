import * as React from 'react';
import {useEffect, useState} from 'react';
import {Dayjs} from 'dayjs';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {Grid} from "@mui/material";
import {Pair} from "../../model/Pair";
import {buildDate} from "../../utils/DateUtils";

interface TimePickerParams {
    range: Pair<number, number>;
    onDateRangeChanged: any;
}

const TimePicker = ({range, onDateRangeChanged}: TimePickerParams) => {
    const [from, setFrom] = useState<Dayjs>(buildDate(range.first));
    const [to, setTo] = useState<Dayjs>(buildDate(range.second));

    useEffect(() => {
        onDateRangeChanged({
            first: from.unix() * 1000,
            second: to.unix() * 1000 + 999
        })
    }, [from, to]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container columnSpacing={{xs: 1,}} className='wrap-datepicker' style={{marginBlock: '15px'}}>
                <Grid item>
                    <DatePicker
                        views={['day']}
                        value={from}
                        onChange={(newValue) => {
                            setFrom(newValue!!);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null}/>}
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        views={['day']}
                        value={to}
                        onChange={(newValue) => {
                            setTo(newValue!!);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null}/>}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}

export default TimePicker;
