import React from 'react';

const AuthFailure = () => {
    return (
        <>
            <div className='failure' style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><h3>Authentication is failed</h3></div>
        </>
    );
}

export default AuthFailure;
