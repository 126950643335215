import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {Page} from "../../model/Page";
import {ItemReport} from "../../model/ItemReport";
import {formatDate, formatDateTime} from "../../utils/DateUtils";
import {SortDirection} from "../../model/SortDirection";
import {ReportsItemSortField} from "../../model/ReportsItemSortField";
import {getItemsReports, getOrderReports} from "../../api/api";
import {useEffect} from "react";

var CurrencyFormat = require('react-currency-format');

var emptyPage: Page<ItemReport> = {
    content: [],
    last: false,
    totalPages: 0,
    totalElements: 0,
    number: 0,
    size: 50,
    first: true,
    numberOfElements: 0,
    empty: false,
    anyAtAll: true,
};

interface Data {
    itemName: number;
    sky: number;
    productCode: number;
    priceType: string;
    cloverOriginalPrice: number;
    increasedPrice: number;
    increasedDate: number;
}

function createData(
    priceType: string,
    itemName: any,
    productCode: number,
    sky: number,
    cloverOriginalPrice: number,
    increasedPrice: number,
    increasedDate: number,
): Data {
    return {
        priceType,
        itemName,
        productCode,
        sky,
        cloverOriginalPrice,
        increasedPrice,
        increasedDate,
    };
}


type Order = 'asc' | 'desc';


interface HeadCell {
    disablePadding: boolean;
    id: ReportsItemSortField;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: ReportsItemSortField.PRICE_TYPE,
        numeric: true,
        disablePadding: false,
        label: 'Price type',
    },
    {
        id: ReportsItemSortField.ITEM_NAME,
        numeric: true,
        disablePadding: false,
        label: 'Item name',
    },
    {
        id: ReportsItemSortField.PRODUCT_CODE,
        numeric: true,
        disablePadding: false,
        label: 'Product code',
    },
    {
        id: ReportsItemSortField.PRODUCT_SKU,
        numeric: true,
        disablePadding: false,
        label: 'SKU',
    },
    {
        id: ReportsItemSortField.ORIGINAL_PRICE,
        numeric: true,
        disablePadding: false,
        label: 'Clover original price',
    },
    {
        id: ReportsItemSortField.INCREASED_PRICE,
        numeric: true,
        disablePadding: false,
        label: 'Increased price',
    },
    {
        id: ReportsItemSortField.INCREASED_DATE,
        numeric: true,
        disablePadding: false,
        label: 'Increased date',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: ReportsItemSortField) => void;
    order: SortDirection;
    orderBy: ReportsItemSortField;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: ReportsItemSortField) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className='table-header'>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                        style={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const TableItems = () => {
    const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc');
    const [orderBy, setOrderBy] = React.useState<ReportsItemSortField>(ReportsItemSortField.ITEM_NAME);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(emptyPage.size);
    const [resultPage, setResultPage] = React.useState(emptyPage);

    useEffect(() => {
        getItems();
    }, [page, sortDirection, orderBy])

    function getItems() {
        getItemsReports(getOffset(), rowsPerPage, sortDirection, orderBy)
            .then((result) => {
                if (result.status === 200) {
                    result.json()
                        .then((body) => {
                            setResultPage(emptyPage)
                            setResultPage(body);
                        })
                } else {
                    console.log(result);
                    // TODO handle error
                }
            })
    }

    function getOffset(): number {
        return page * rowsPerPage;
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: ReportsItemSortField,
    ) => {
        const isAsc = orderBy === property && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, priceType: string) => {
        const selectedIndex = selected.indexOf(priceType);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, priceType);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = !resultPage.anyAtAll;

    const priceTypeFormatted = (priceType: string) => {
        return priceType[0] + priceType.slice(1).toLowerCase().replace('_', ' ');
    }
    const increasedPriceFormatted = (row: ItemReport) => {
        if (row.increasedTs != null && row.increasedPrice != null) {
            return row.increasedPrice / 100;
        } else {
            return '';
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
                <TableContainer sx={{ maxHeight: '74vh' }}>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        stickyHeader
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={sortDirection}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={resultPage.size}
                        />
                        <TableBody>
                            {resultPage.content
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.priceType);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.priceType)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.cloverId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" padding={'normal'}>
                                                {priceTypeFormatted(row.priceType)}
                                            </TableCell>
                                            <TableCell align="left">{row.itemName}</TableCell>
                                            <TableCell align="left">{row.productCode}</TableCell>
                                            <TableCell align="left">{row.productSKU}</TableCell>
                                            <TableCell align="left">
                                                {row.priceType !== 'VARIABLE' && <CurrencyFormat
                                                    value={row.cloverOriginalPrice / 100}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    prefix={'$'}/>
                                                }
                                                {row.priceType === 'VARIABLE' && <span>Variable</span>}
                                            </TableCell>
                                            <TableCell align="left">
                                                {/*#25cbb2*/}
                                                <CurrencyFormat value={increasedPriceFormatted(row)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                prefix={'$'}/>
                                            </TableCell>
                                            <TableCell align="left">{formatDate(row.increasedTs)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows && (
                                <TableRow
                                    style={{
                                        height: 53,
                                    }}
                                >
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[resultPage.size]}
                    component="div"
                    count={resultPage.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export default TableItems;
