import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {ReportsOrderSortField} from "../../model/ReportsOrderSortField";
import {OrderReport} from "../../model/OrderReport";
import {SortDirection} from "../../model/SortDirection";
import {getOrderReports} from "../../api/api";
import {Page} from "../../model/Page";
import DateFilter from "./DateFilter";
import {Pair} from "../../model/Pair";
import {formatDateTime, getTsRangeToday} from "../../utils/DateUtils";
import {Grid, Typography} from "@mui/material";

let CurrencyFormat = require('react-currency-format');

let emptyPage: Page<OrderReport> = {
    content: [],
    last: false,
    totalPages: 0,
    totalElements: 0,
    number: 0,
    size: 50,
    first: true,
    numberOfElements: 0,
    empty: false,
    anyAtAll: true,
};

interface HeadCell {
    disablePadding: boolean;
    id: ReportsOrderSortField;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: ReportsOrderSortField.ORDER_DATE,
        numeric: true,
        disablePadding: false,
        label: 'Order date',
    },
    {
        id: ReportsOrderSortField.ORDER_ID,
        numeric: true,
        disablePadding: false,
        label: 'Order ID',
    },
    {
        id: ReportsOrderSortField.SUBTOTAL,
        numeric: true,
        disablePadding: false,
        label: 'Subtotal',
    },
    {
        id: ReportsOrderSortField.TOTAL,
        numeric: true,
        disablePadding: false,
        label: 'Total',
    },
    {
        id: ReportsOrderSortField.DISCOUNT_AMOUNT,
        numeric: true,
        disablePadding: false,
        label: 'Discount Amount',
    },
    {
        id: ReportsOrderSortField.DISCOUNT_NAME,
        numeric: true,
        disablePadding: false,
        label: 'Discount Name',
    },
    {
        id: ReportsOrderSortField.TENDER_NAME,
        numeric: true,
        disablePadding: false,
        label: 'Tender Name',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: ReportsOrderSortField) => void;
    order: SortDirection;
    orderBy: ReportsOrderSortField;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: ReportsOrderSortField) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className='table-header'>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                        style={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const TablePayments = () => {

    const [sortDirection, setSortDirection] = React.useState<SortDirection>('desc');
    const [orderBy, setOrderBy] = React.useState<ReportsOrderSortField>(ReportsOrderSortField.ORDER_DATE);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(emptyPage.size);
    const [resultPage, setResultPage] = React.useState(emptyPage);
    const [fromTs, setFromTs] = React.useState(getTsRangeToday().first);
    const [toTs, setToTs] = React.useState(getTsRangeToday().second);
    const [resultTotalAmount, setResultTotalAmount] = React.useState(0);

    function getOffset(): number {
        return page * rowsPerPage;
    }

    function getOrders() {
        getOrderReports(getOffset(), rowsPerPage, fromTs, toTs, sortDirection, orderBy)
            .then((result) => {
                if (result.status === 200) {
                    result.json()
                        .then((body) => {
                            setResultPage(emptyPage)
                            setResultPage(body);
                        })
                } else {
                    console.log(result);
                    // TODO handle error
                }
            })
    }


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: ReportsOrderSortField,
    ) => {
        const isAsc = orderBy === property && sortDirection === 'asc';
        const newSortDirection = isAsc ? 'desc' : 'asc';
        setSortDirection(newSortDirection);
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, orderDate: number) => {
        const selectedIndex = selected.indexOf(orderDate.toString());
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, orderDate.toString());
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const totalAmount = resultPage.content.map(i => i.discountAmount)
    const arraySum = ({array}: { array: any }) => {
        let sum = 0;
        for(let i = 0; i < array.length; i++){
            sum += array[i];
        }
        setResultTotalAmount(sum)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: number) => selected.indexOf(name.toString()) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = !resultPage.anyAtAll

    // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    function getTenderName(tenderType: string): string {
        const indexLastPoint = tenderType.lastIndexOf('.');
        const tenderName = tenderType.slice(indexLastPoint + 1);
        return tenderName.charAt(0).toUpperCase() + tenderName.slice(1).replace('_', ' ');
    }

    const onDateRange = (range: Pair<number, number>) => {
        setFromTs(range.first);
        setToTs(range.second);
    }

    useEffect(() => {
        getOrders();
    }, [fromTs, toTs, page, sortDirection, orderBy]);

    useEffect(() => {
        arraySum({array: totalAmount});
    }, [resultTotalAmount, resultPage]);

    return (
        <>
            <Grid container columnSpacing={{ xs: 1}} className='wrap-container'>
                <DateFilter onDateRangeChanged={onDateRange}/>
                <Typography variant="body1" gutterBottom style={{marginLeft: 'auto', marginBottom: 0}}>Total amount saved during this time period: <CurrencyFormat value={resultTotalAmount / 100}
                                                                                                                                                                   displayType={'text'}
                                                                                                                                                                   decimalScale={2}
                                                                                                                                                                   fixedDecimalScale={true}
                                                                                                                                                                   thousandSeparator={true}
                                                                                                                                                                   prefix={'$'}/></Typography>
            </Grid>
            <Paper sx={{width: '100%', mb: 2}}>
                {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
                <TableContainer sx={{ maxHeight: '74vh' }}>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        stickyHeader
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={sortDirection}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={resultPage.size}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
                            {resultPage.content
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.orderCreatedTs);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.orderCreatedTs)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.orderCreatedTs}
                                            selected={isItemSelected}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding={'normal'}
                                            >
                                                {formatDateTime(row.orderCreatedTs)}
                                            </TableCell>
                                            <TableCell align='left'>{row.orderId}</TableCell>
                                            <TableCell align='left'><CurrencyFormat value={row.orderSubtotal / 100}
                                                                                     displayType={'text'}
                                                                                     decimalScale={2}
                                                                                     fixedDecimalScale={true}
                                                                                     thousandSeparator={true}
                                                                                     prefix={'$'}/></TableCell>
                                            <TableCell align='left'><CurrencyFormat value={row.orderAmount / 100}
                                                                                     displayType={'text'}
                                                                                     decimalScale={2}
                                                                                     fixedDecimalScale={true}
                                                                                     thousandSeparator={true}
                                                                                     prefix={'$'}/></TableCell>
                                            <TableCell align='left'><CurrencyFormat value={row.discountAmount / 100}
                                                                                     displayType={'text'}
                                                                                     decimalScale={2}
                                                                                     fixedDecimalScale={true}
                                                                                     thousandSeparator={true}
                                                                                     prefix={'$'}/></TableCell>
                                            <TableCell align='left'>{row.discountName}</TableCell>
                                            <TableCell align='left'>{getTenderName(row.tenderType)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows && (
                                <TableRow
                                    style={{
                                        height: 53,
                                    }}
                                >
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[resultPage.size]}
                    component="div"
                    count={resultPage.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default TablePayments;
