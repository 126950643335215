import {DeleteMessageBatchCommand, Message, ReceiveMessageCommand} from "@aws-sdk/client-sqs";
import Config from "./Config";
import {DeleteMessageBatchCommandInput} from "@aws-sdk/client-sqs/dist-types/commands/DeleteMessageBatchCommand";
import {DeleteMessageBatchRequestEntry} from "@aws-sdk/client-sqs/dist-types/models/models_0";

export function buildQueueUrl(merchantId: string, deviceId: string): string {
    const envPrefix = Config.ENV_NAME.charAt(0);
    // const fifoSuffix = ".fifo"
    const url = `${envPrefix}_${merchantId}_${deviceId}`;
    return url
}

export function buildDeleteMessageBatchCommand(queueUrl: string, messages: Message[]): DeleteMessageBatchCommand {
    const input: DeleteMessageBatchCommandInput = {
        QueueUrl: queueUrl,
        Entries: messages.map(value => {
            return {
                Id: value.MessageId,
                ReceiptHandle: value.ReceiptHandle,
            }
        }),
    };
    return new DeleteMessageBatchCommand(input);
}

export function buildReceiveMessageCommand(queueUrl: string): ReceiveMessageCommand {
    const input = {
        QueueUrl: queueUrl, // required
        AttributeNames: [ // AttributeNameList
            "SentTimestamp"
            // "All" || "Policy" || "VisibilityTimeout" || "MaximumMessageSize" || "MessageRetentionPeriod"
            // || "ApproximateNumberOfMessages" || "ApproximateNumberOfMessagesNotVisible" || "CreatedTimestamp"
            // || "LastModifiedTimestamp" || "QueueArn" || "ApproximateNumberOfMessagesDelayed" || "DelaySeconds"
            // || "ReceiveMessageWaitTimeSeconds" || "RedrivePolicy" || "FifoQueue" || "ContentBasedDeduplication"
            // || "KmsMasterKeyId" || "KmsDataKeyReusePeriodSeconds" || "DeduplicationScope" || "FifoThroughputLimit"
            // || "RedriveAllowPolicy" || "SqsManagedSseEnabled",
        ],
        MessageAttributeNames: [ // MessageAttributeNameList
            // "STRING_VALUE",
        ],
        MaxNumberOfMessages: Number(10),
        VisibilityTimeout: Number(5),
        WaitTimeSeconds: Number(2),
        // ReceiveRequestAttemptId: "STRING_VALUE",
    }
    return new ReceiveMessageCommand(input)
}