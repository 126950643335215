import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';
import {List, ListItem} from "@mui/material";
import {DoublePricingDiscount, DoublePricingLineItem, DoublePricingModifier} from "../../model/DoublePricingOrder";
import {useEffect, useRef} from "react";

const OrderList: React.FC<{ order: any}> = (props) => {
    const {order} = props;
    console.log('order', order)
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.scrollTo(0, scrollContainer.scrollHeight); // Прокрутити до кінц�? �?пи�?ку
        }
    }, [order]);

    return (
        <Box className={'scrollbar'} ref={scrollContainerRef} sx={{ width: '100%', marginX: 'auto', flexGrow: 1, overflowY: 'auto', flexBasis: '100%'}}>
            <List
                component="ul"
                sx={{
                    py: 0,
                }}
            >
                {order && order.lineItems ? order.lineItems.map((i: DoublePricingLineItem) => (
                    <ListItem className={order.lineItems.length > 1 ? 'order-lineitem-wrapper' : 'order-lineitem-wrapper one'} divider>
                        <List
                            component="ul"
                            sx={{
                                py: 0,
                                width: '100%'
                            }}
                        >
                            {i.amount.amount1 != undefined ? <ListItem className={'order-list-item'}>
                                <Typography className={'order-title-big'} variant="subtitle1">{i.name} </Typography>
                                <Typography className={'order-title-small order-title-count'} variant="subtitle1">x{i.count} </Typography>
                                <Typography className={'wrap-amount'}>
                                    <Typography className={'order-title-big'} variant="subtitle1">{i.amount.amount1} </Typography>
                                    <Typography className={'order-title-big'} variant="subtitle1">{i.amount.amount2} </Typography>
                                </Typography>
                            </ListItem> : undefined}

                            {i.unitQty !== undefined ? <ListItem className={'order-list-item'}>
                                <Typography className={'order-title-small'} variant="subtitle1">{i.unitQty} </Typography>
                            </ListItem> : undefined}

                            {i.modifiers != null ? i.modifiers.map((modifier: DoublePricingModifier) => (<ListItem sx={{
                                        p: 0,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                    }}>
                                        <Typography className={'order-title-small'} variant="subtitle1">{modifier.name} </Typography>
                                        <Typography className={'wrap-amount'}>
                                            <Typography className={'order-title-small'} variant="subtitle1">{modifier.amount.amount1} </Typography>
                                            <Typography className={'order-title-small'} variant="subtitle1">{modifier.amount.amount2} </Typography>
                                        </Typography>
                                    </ListItem>))
                                : undefined}
                            {i.note !== undefined ? <ListItem className={'order-list-item'}>
                                <Typography className={'order-title-small'} variant="subtitle1">{i.note} </Typography>
                            </ListItem> : undefined}
                            {i.discounts !=undefined && i.discounts.length > 0 ? i.discounts.map((i:DoublePricingDiscount) => (<ListItem sx={{
                                p: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: 1.43,
                                letterSpacing: '0.01071em',
                                color: 'rgba(232,55,55,0.6)',
                            }}>
                                <Typography className={'order-title-small'} variant="subtitle1">{i.name} </Typography>
                                <Typography className={'wrap-amount'}>
                                    <Typography className={'order-title-small'} variant="subtitle1">{i.amount.a1 === 0 ? '' : `-${i.amount.amount1}`} </Typography>
                                    <Typography className={'order-title-small'} variant="subtitle1">{i.amount.a2 === 0 ? '' : `-${i.amount.amount2}`} </Typography>
                                </Typography>
                            </ListItem>))
                             : undefined}
                        </List>
                    </ListItem>
                )) : undefined}
            </List>
        </Box>
    );
}
export default OrderList;
