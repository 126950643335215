import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(tz)

const ONE_DAY_MS = 24 * 60 * 60 * 1000

export function getTsRangeToday() {
    const start = dayjs().startOf('day').unix() * 1000;
    const end = dayjs().endOf('day').unix() * 1000 + 999;
    return {
        first: start,
        second: end
    }
}

export function getTsRangeYesterday() {
    const todayRange = getTsRangeToday()
    return {
        first: todayRange.first - ONE_DAY_MS,
        second: todayRange.second - ONE_DAY_MS
    }
}

export function getTsRange7Days() {
    const todayRange = getTsRangeToday()
    return {
        first: todayRange.first - (6 * ONE_DAY_MS),
        second: todayRange.second
    }
}

export function getTsRange30Days() {
    const todayRange = getTsRangeToday()
    return {
        first: todayRange.first - (29 * ONE_DAY_MS),
        second: todayRange.second
    }
}

export function formatDateTime(ms?: number): string {
    if (ms == null) return ''
    return dayjs.utc(ms).local().format('MM-DD-YYYY HH:mm:ss')
}

export function formatDate(ms?: number, onEmpty?: string): string {
    if (ms == null) return onEmpty != null ? onEmpty : ''
    return dayjs.utc(ms).local().format('MM-DD-YYYY')
}

export function buildDate(ms: number): Dayjs {
    return dayjs.utc(ms).local()
}
