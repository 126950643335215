import React, {useEffect} from 'react';
import {AuthModel} from "../../model/AuthModel";
import {auth} from "../../api/api";
import {PathConst} from "../../utils/PathConst";
import {CircularProgress} from "@mui/material";
import {StorageConst} from "../../utils/StorageConst";
import {useHistory} from "react-router-dom";


const Auth = () => {

    const history = useHistory();

    function navigate(uri: string) {
        history.push(uri);
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const merchId = searchParams.get("merchant_id")!!
        const authModel: AuthModel = {
            merchantId: merchId,
            employeeId: searchParams.get("employee_id")!!,
            clientId: searchParams.get("client_id")!!,
            code: searchParams.get("code")!!,
        }

        auth(authModel)
            .then((response) => {
                if (response.status == 200) {
                    response.json()
                        .then((body) => {
                            sessionStorage.setItem(StorageConst.ACCESS_TOKEN, body.access_token);
                            sessionStorage.setItem(StorageConst.MERCHANT_ID, merchId);
                            navigate(PathConst.DASHBOARD);
                        });
                } else {
                    navigate(PathConst.AUTH_FAILURE);
                }
            })

    })

    return (
        <>
            <center style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </center>
        </>
    );
}

export default Auth;
