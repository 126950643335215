import React from 'react';
import FullWidthTabs from "../Tabs";
import Header from "../Header";

const Dashboard = () => {
    return (
        <>
            <Header/>
            <FullWidthTabs/>
        </>
    );
}

export default Dashboard;
