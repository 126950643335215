import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableItems from "../TableItems";
import TablePayments from "../TablePayments";



export default function FullWidthTabs() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'fixed', top: '52px', left: 0, width: '100%', background: 'white', zIndex: 10}}>
                    <TabList className='wrap-tab-btn' onChange={handleChange} aria-label="lab API tabs example">
                        <Tab className='tab-btn' label="Payments" value="1" />
                        <Tab className='tab-btn' label="Items" value="2" />
                    </TabList>
                </Box>
                <Box sx={{ marginTop: '104px'}}>
                    <TabPanel value="1">
                        <TablePayments />
                    </TabPanel>
                    <TabPanel value="2">
                        <TableItems />
                    </TabPanel>
                </Box>
            </TabContext>
        </>
    );
}
